<template>
  <div id="member">
    <div class="user_main_center wrap">
      <div class="subnav fl">
        <div
          class="nav_title"
          style="cursor: pointer"
          @click="$router.push('/member/index')"
        >
          <div>会员中心首页</div>
        </div>
        <div class="nav_send_goods">
          <router-link to="/member/send_order"
            ><img
              src="@/assets/img/13.png"
              alt=""
              style="height: 42px; width: 120px"
          /></router-link>
        </div>
        <div class="nav_list">
          <div class="title">
            <i class="el-icon-setting"></i>
            <span>订单管理</span>
          </div>
          <div class="nav_item">
            <router-link to="order">订单列表</router-link>
          </div>
          <div class="nav_item">
            <router-link to="receipt">包裹列表</router-link>
          </div>
        </div>
        <div class="nav_list">
          <div class="title">
            <i class="el-icon-setting"></i>
            <span>财务中心</span>
          </div>
          <div class="nav_item">
            <router-link to="recharge">我要充值</router-link>
          </div>
          <div class="nav_item">
            <router-link to="money_log">资金明细</router-link>
          </div>
        </div>
        <div class="nav_list">
          <div class="title">
            <i class="el-icon-setting"></i>
            <span>售后服务</span>
          </div>
          <div class="nav_item">
            <router-link to="check_pieces">售后查件</router-link>
          </div>
          <div class="nav_item">
            <router-link to="advice">意见反馈</router-link>
          </div>
          <div class="nav_item">
            <router-link to="advice_list">反馈列表</router-link>
            <span class="red_point" v-if="unreadNum > 0">
              {{ unreadNum }}
            </span>
          </div>
        </div>
        <div class="nav_list">
          <div class="title">
            <i class="el-icon-setting"></i>
            <span>账户管理</span>
          </div>
          <div class="nav_item">
            <router-link to="vip">价格列表</router-link>
          </div>
          <div class="nav_item">
            <router-link to="edit_password">修改密码</router-link>
          </div>
          <div class="nav_item">
            <router-link to="edit_member">会员资料</router-link>
          </div>
          <div class="nav_item">
            <router-link to="inviter">推广赚钱</router-link>
          </div>
        </div>
        <div class="nav_list">
          <div class="title">
            <i class="el-icon-setting"></i>
            <span>其它</span>
          </div>
          <div class="nav_item">
            <router-link to="/news">帮助中心</router-link>
          </div>
          <div class="nav_item">
            <router-link to="about">关于我们</router-link>
          </div>
        </div>
      </div>
      <div class="subnav_content fr">
        <div class="user_temp">
          <router-view></router-view>
        </div>
      </div>
      <div class="kf">
        <div class="wechat">
          <img src="@/assets/img/weixin.png" alt="" />
          <p>微信咨询</p>
          <div class="block">
            <img :src="$store.state.tenantInfo.wechat | fullPath" alt="" />
          </div>
        </div>
        <div class="wechat">
          <img src="@/assets/img/qq.png" alt="" />
          <p>QQ咨询</p>
          <div class="block">
            <a @click="openQQ()">
              <div class="btn clearfix">
                <img src="@/assets/img/qq.png" alt="" />
                <span>在线咨询</span>
              </div>
            </a>
            <p>{{ $store.state.tenantInfo.qq }}</p>
          </div>
        </div>
        <div class="wechat" @click="toTop">
          <img src="@/assets/img/up.png" alt="" />
          <p>返回顶部</p>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <el-dialog
      title="系统公告"
      :visible.sync="dialogVisible"
      width="700px"
      top="7vh"
      center
    >
      <div class="el-dialog-div">
        <div class="articleTitle">{{ newestSysArticle.title }}</div>
        <div class="other">
          <div>
            来源：{{
              $store.state.tenantInfo.company
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div>
            时间：{{
              $moment(newestSysArticle.createTime).format("Y-MM-DD HH:mm")
            }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div>
            浏览量：{{ newestSysArticle.viewNum }}
            次&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
        <div class="line"></div>
        <div class="detail" v-html="newestSysArticle.content"></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          @click="otherRead(newestSysArticle.id, newestSysArticle.type)"
          >查看详情</el-button
        >
        <el-button
          type="success"
          v-if="isShowRead"
          @click="read(newestSysArticle.id)"
          >标记为已读</el-button
        >
        <el-button type="info" v-if="!isShowRead && isFirst">{{
          readCode
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="会员中心"
        :visible.sync="centerDialogVisible"
        width="700px"
        top="7vh"
        center
    >
      <div
          class=""
          @click="toUrl(advert.url)"
      >
        <img :src="advert.image" style="width: 100%">
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      aboutShow: 1,
      advertList: [],
      advert:{
        image:"",
        url:""
      },
      showLink: 1,
      showLink2Url: "",
      newestSysArticle: {},
      dialogVisible: false,
      isFirst: true,
      isShowRead: false,
      readCode: "10秒后可标记为已读",
      unreadNum: 0,
      centerDialogVisible:false
    };
  },
  created() {
    var hostName = window.location.host;
    this.getAdvertList();



  },
  mounted() {},
  watch: {},
  methods: {
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1 && value.indexOf("http")) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.50:8080" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }

      return result;
    },
    toTop() {
      let scrollToTop = window.setInterval(function () {
        var pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50);
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    },
    openQQ() {
      var url =
        "tencent://message/?uin=" +
        this.$store.state.tenantInfo.qq +
        "&amp;Site=&amp;Menu=yes";
      window.open(url, "_self");
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          pageNo: 1,
          pageSize: 1,
          type: 5,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {

          if(res.length>0){
              this.advert = res[0];
              this.centerDialogVisible = true
          }
          this.advertList = res;
        },
      });
    },
    toUrl(url) {
      if(url !=null && url!=''){
        if (url.indexOf("http") != -1) {
          window.open(url);
        }
      }
    },

































































  },
};
</script>

<style lang="scss">
.detail {
  img {
    width: 100%;
  }
}
.el-dialog-div {
  height: 65vh;
  overflow: auto;
}
</style>
<style lang="scss" scoped>

#member {
  background: #f2f2f2;
  padding: 10px 0;
  .articleTitle {
    text-align: center;
    color: #ea242f;
    font-weight: bold;
    margin-top: 30px;
    font-size: 18px;
  }
  .other {
    display: flex;
    width: 485px;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .line {
    border-bottom: 1px dashed #999;
    margin-bottom: 20px;
  }
  .red_point {
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 5px;
    height: 15px;
    line-height: 17px;
    padding: 0 3px;
    text-align: center;
    white-space: nowrap;
    margin-left: 5px;
  }
  .red_point1 {
    background-color: #f56c6c;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
  }

  .user_main_center {
    .user_adv {
      cursor: pointer;
      margin-bottom: 10px;
      img {
        width: 1200px;
      }
      height: 100%;
    }
    .subnav {
      width: 160px;
      background: #fff;
      border-radius: 6px;
      padding-bottom: 20px;
      .nav_title {
        width: 160px;
        background: #ea242f;
        color: #fff;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        border-radius: 6px 6px 0 0;
        a {
          color: #fff;
        }
      }
      .nav_send_goods {
        width: 113px;

        height: auto;
        display: block;
        margin: 10px auto 20px auto;
      }
      .nav_list {
        color: #333;
        padding-left: 20px;
        line-height: 35px;
        border-bottom: 1px solid #dedede;
        padding-bottom: 10px;
        margin-bottom: 10px;
        a {
          &:hover {
            color: #ea242f;
          }
        }
        .nav_item {
          font-size: 14px;
          margin-left: 30px;
          .router-link-active {
            color: #ea242f;
          }
        }
        .el-icon-setting {
          margin-right: 10px;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .subnav_content {
      width: 1020px;
      min-height: 850px;
      margin-bottom: 30px;
    }
    .kf {
      width: 70px;
      min-height: 170px;
      position: fixed;
      right: 15px;
      top: 200px;
      border-radius: 6px;
      background: #ea242f;
      color: #fff;
      z-index: 999;
      .wechat {
        cursor: pointer;
        position: relative;
        padding: 10px;
        text-align: center;
        border-radius: 6px;
        border-bottom: 1px solid #ea242f;
        font-size: 12px;
        &:hover {
          background: #c2242c;
        }
        &:nth-child(1):hover .block {
          left: -120px;
          top: 0px;
          display: block;
        }
        &:nth-child(3):hover .block {
          left: -120px;
          top: 0px;
          display: block;
        }
        &:nth-child(2):hover .block {
          display: block;
          left: -127px;
          top: 0px;
          padding-top: 10px;
          padding-bottom: 10px;
          .btn {
            background: #ea242f;
            line-height: 31px;
            color: #fff;
            border-radius: 3px;
            margin: 0 10px;
            padding: 0 10px;
            img {
              width: 14px;
              height: 14px;
              float: left;
              margin-top: 8px;
              margin-right: 5px;
            }
            span {
              float: left;
            }
          }
          p {
            color: #666;
            font-weight: bold;
          }
        }
        img {
          margin-top: 10px;
        }
        p {
          margin-top: 10px;
        }
        .block {
          position: absolute;
          display: none;
          margin: 0;
          padding: 10px;
          background: #fff;
          border-radius: 6px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          img {
            margin-top: 10px;
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
